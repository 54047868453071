.Kalend__text {
  padding: 0;
  margin: 0;
  font-family: MONOSPACE, SansSerif, sans-serif, serif;
  font-size: 1em; }

.Kalend__Calendar__root, .Kalend__Calendar__root-dark {
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
  font-size: 10px;
  overflow-x: hidden; }
  .Kalend__Calendar__root-dark {
    background-color: #1D1F26; }

.Kalend__main * {
  font-family: MONOSPACE, SansSerif, sans-serif, serif; }

.Kalend__Calendar__table, .Kalend__Calendar__table-dark {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  position: relative; }
  .Kalend__Calendar__table-dark {
    background-color: #1D1F26; }

.Kalend__Calendar__header-surface {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: auto; }
  .Kalend__Calendar__header-surface-dark {
    background-color: #1D1F26; }

.Kalend__Calendar__table-surface, .Kalend__Calendar__header-surface-dark, .Kalend__Calendar__table-surface-dark {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  position: relative; }
  .Kalend__Calendar__table-surface-dark {
    background-color: #1D1F26; }

.Kalend__button {
  padding: inherit;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  appearance: auto;
  letter-spacing: inherit;
  word-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  text-indent: inherit;
  text-shadow: none;
  display: flex;
  text-align: inherit;
  align-items: inherit;
  box-sizing: inherit;
  background-color: transparent;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  border-image: inherit; }

.Kalend__text-light {
  color: #f5f5f5; }

.Kalend__text-dark {
  color: #1D1F26; }

.Kalend__color-text-primary {
  color: #ec407a; }
  .Kalend__color-text-primary-dark {
    color: #f48fb1; }

.Kalend__color-background-primary {
  color: #ec407a; }
  .Kalend__color-background-primary-dark {
    color: #f48fb1; }

.Kalend__color-text-gray300 {
  color: #e0e0e0; }
  .Kalend__color-text-gray300-dark {
    color: #616161; }

.Kalend__color-text-gray800 {
  color: #424242; }
  .Kalend__color-text-gray800-dark {
    color: #eeeeee; }

.Kalend__color-text-base {
  color: #1D1F26; }
  .Kalend__color-text-base-dark {
    color: #eeeeee; }
  .Kalend__color-text-base-grayed {
    color: #757575; }
    .Kalend__color-text-base-grayed-dark {
      color: #bdbdbd; }

.Kalend__CalendarDesktopNavigation__container, .Kalend__CalendarDesktopNavigation__container-mobile, .Kalend__CalendarDesktopNavigation__container-mobile-dark, .Kalend__CalendarDesktopNavigation__container-dark {
  border-radius: 8px;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  height: 70px;
  max-height: 70px;
  min-height: 70px;
  width: auto;
  padding-left: 12px;
  align-items: center; }
  .Kalend__CalendarDesktopNavigation__container-mobile, .Kalend__CalendarDesktopNavigation__container-mobile-dark {
    flex-direction: column;
    min-height: 0; }
    .Kalend__CalendarDesktopNavigation__container-mobile-dark {
      background: #424242;
      border-radius: 0; }

.Kalend__CalendarDesktopNavigation__container-dark {
  background-color: #424242;
  border-radius: 0; }

.Kalend__CalendarDesktopNavigation__buttons, .Kalend__CalendarDesktopNavigation__buttons-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .Kalend__CalendarDesktopNavigation__buttons-mobile {
    justify-content: flex-end; }

.Kalend__calendarButtonToday {
  display: flex;
  padding: 6px 4px 6px 4px;
  justify-content: center;
  align-items: center;
  width: 60px;
  border: solid 0.4px #e0e0e0;
  margin-right: 12px;
  margin-left: 12px; }

.Kalend__Agenda__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }

.Kalend__Agenda__container-empty, .Kalend__Agenda__container-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

.Kalend__Agenda__text-empty, .Kalend__Agenda__text-empty-dark {
  color: #1D1F26;
  font-size: 1.5em;
  text-align: center; }
  .Kalend__Agenda__text-empty-dark {
    color: #f5f5f5; }

.Kalend__AgendaDayRow__container, .Kalend__AgendaDayRow__container-dark {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid #e0e0e0 0.4px; }
  .Kalend__AgendaDayRow__container-dark {
    border-bottom: solid #616161 0.4px; }

.Kalend__AgendaDayRow__events {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.Kalend__AgendaDayRow__container-day {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: auto; }

.Kalend__ButtonBase, .Kalend__ButtonBase-dark, .Kalend__ButtonBase-dark:hover, .Kalend__ButtonBase-normal, .Kalend__ButtonBase-normal:hover, .Kalend__ButtonBase-disabled, .Kalend__ButtonBase-primary, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark, .Kalend__ButtonBase-transparent, .Kalend__ButtonBase-border, .Kalend__ButtonBase-border-dark {
  min-height: 20px;
  font-size: 1.6em;
  border-radius: 10px;
  font-weight: 400;
  border: none;
  outline-style: none;
  cursor: pointer;
  color: #424242;
  background-color: transparent;
  position: relative;
  overflow: hidden; }
  .Kalend__ButtonBase:hover, .Kalend__ButtonBase-dark:hover, .Kalend__ButtonBase-normal:hover, .Kalend__ButtonBase-disabled:hover, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-transparent:hover, .Kalend__ButtonBase-border:hover, .Kalend__ButtonBase-border-dark:hover {
    background-color: #eeeeee; }
  .Kalend__ButtonBase-dark, .Kalend__ButtonBase-dark:hover {
    color: #f5f5f5; }
    .Kalend__ButtonBase-dark:hover {
      background-color: #616161;
      color: #f5f5f5; }
  .Kalend__ButtonBase-normal, .Kalend__ButtonBase-normal:hover {
    border: none;
    background-color: #424242;
    padding: 4px 12px 4px 12px;
    color: #f5f5f5; }
    .Kalend__ButtonBase-normal:hover {
      background-color: #757575; }
  .Kalend__ButtonBase-disabled {
    background-color: #bdbdbd;
    color: #616161; }
  .Kalend__ButtonBase-primary, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark {
    padding: 4px 12px 4px 12px;
    background-color: #ec407a;
    color: #eeeeee; }
    .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark:hover {
      background-color: rgba(236, 64, 122, 0.7); }
    .Kalend__ButtonBase-primary-dark {
      background-color: #f48fb1;
      color: #424242; }
  .Kalend__ButtonBase-transparent {
    padding: 4px 12px 4px 12px;
    color: #616161;
    background-color: transparent; }
    .Kalend__ButtonBase-transparent:hover {
      background-color: #eeeeee; }

.Kalend__ButtonBase__animation {
  position: absolute;
  transform: scale(0);
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 50%;
  animation: Kalend__ripple__animation 500ms ease-in; }

@keyframes Kalend__ripple__animation {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.Kalend__ButtonBase__loader-circle, .Kalend__ButtonBase__loader-circle-left, .Kalend__ButtonBase__loader-circle-middle, .Kalend__ButtonBase__loader-circle-right {
  width: 12px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 6px;
  margin: 0 6px 0 6px;
  opacity: 0;
  animation-iteration-count: infinite;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-name: Kalend__ButtonBase__animation-pulse-loader;
  animation-delay: 0s; }
  .Kalend__ButtonBase__loader-circle-left {
    animation-delay: 0s; }
  .Kalend__ButtonBase__loader-circle-middle {
    animation-delay: 0.3s; }
  .Kalend__ButtonBase__loader-circle-right {
    animation-delay: 0.5s; }

@keyframes Kalend__ButtonBase__animation-pulse-loader {
  0% {
    opacity: 0;
    background-color: #9e9e9e; }
  50% {
    opacity: 1;
    background-color: #e0e0e0; }
  100% {
    opacity: 0; } }

.Kalend__Button-long, .Kalend__Button-long:hover {
  width: 100%;
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.Kalend__ButtonBase-border, .Kalend__ButtonBase-border-dark {
  display: flex;
  padding: 6px 8px 6px 8px;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  width: auto;
  border: solid 0.4px #e0e0e0;
  margin-right: 12px;
  margin-left: 12px; }
  .Kalend__ButtonBase-border-dark {
    border: solid 0.4px #9e9e9e;
    color: #eeeeee; }
    .Kalend__ButtonBase-border-dark:hover {
      border: solid 0.4px #757575;
      background-color: #757575; }

.Kalend__ButtonIcon__container, .Kalend__ButtonIcon__container-small, .Kalend__ButtonIcon__container-big, .Kalend__ButtonIcon__container-full {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative; }
  .Kalend__ButtonIcon__container-small {
    width: 30px;
    height: 30px;
    border-radius: 15px; }
  .Kalend__ButtonIcon__container-big {
    width: 55px;
    height: 55px;
    border-radius: 27.5px; }
  .Kalend__ButtonIcon__container-full {
    width: 100%;
    height: 100%;
    border-radius: 50%; }

.Kalend__ButtonIcon, .Kalend__ButtonIcon:hover, .Kalend__ButtonIcon-dark, .Kalend__ButtonIcon-dark:hover, .Kalend__ButtonIcon-inactive, .Kalend__ButtonIcon-inactive:active, .Kalend__ButtonIcon-inactive:hover {
  fill: #757575;
  background-color: transparent;
  font-size: 1.4em;
  border: none;
  outline-style: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 27.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; }
  .Kalend__ButtonIcon:hover, .Kalend__ButtonIcon-dark:hover, .Kalend__ButtonIcon-inactive:hover {
    opacity: 0.9;
    background-color: #e0e0e0; }
  .Kalend__ButtonIcon-dark, .Kalend__ButtonIcon-dark:hover {
    fill: #bdbdbd; }
    .Kalend__ButtonIcon-dark:hover {
      opacity: 0.9;
      background-color: #616161; }
  .Kalend__ButtonIcon-inactive:active {
    background-color: transparent;
    animation: none; }
  .Kalend__ButtonIcon-inactive:hover {
    background-color: transparent; }

.Kalend__ButtonIcon__disabled {
  cursor: default !important; }
  .Kalend__ButtonIcon__disabled:hover {
    background-color: transparent !important; }
  .Kalend__ButtonIcon__disabled:active {
    background-color: transparent !important; }

.Kalend__ButtonIcon__svg, .Kalend__ButtonIcon__svg-normal, .Kalend__ButtonIcon__svg-normal-dark, .Kalend__ButtonIcon__svg-dark {
  width: 100%;
  height: 100%;
  padding: 1px;
  z-index: 5;
  fill: #757575; }
  .Kalend__ButtonIcon__svg-normal, .Kalend__ButtonIcon__svg-normal-dark {
    width: 2.2em;
    height: 2.2em; }
    .Kalend__ButtonIcon__svg-normal-dark {
      fill: #e0e0e0; }
  .Kalend__ButtonIcon__svg-dark {
    fill: #e0e0e0; }

.Kalend__ButtonIcon__backdrop, .Kalend__ButtonIcon__backdrop:active {
  position: absolute;
  background-color: #bdbdbd;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-name: Kalend__ButtonIcon__animation-backdrop;
  animation-delay: 0s;
  animation-duration: 0.4s; }
  .Kalend__ButtonIcon__backdrop:active {
    background-color: #757575;
    animation-name: Kalend__ButtonIcon__animation-press;
    animation-duration: 0.1s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards; }

@keyframes Kalend__ButtonIcon__animation-press {
  0% {
    background-color: #f5f5f5;
    opacity: 0.5; }
  100% {
    background-color: #e0e0e0;
    opacity: 1; } }

@keyframes Kalend__ButtonIcon__animation-backdrop {
  0% {
    background-color: #f5f5f5;
    width: 0;
    height: 0; }
  100% {
    background-color: #bdbdbd;
    width: 100%;
    height: 100%; } }

.Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
  border-radius: 8px;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  width: 100%;
  padding-bottom: 6px;
  box-shadow: 0 5px 5px -5px #333;
  z-index: 10; }
  @media (max-width: 750px) {
    .Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
      border-radius: 0px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14); } }
  .Kalend__CalendarHeader-dark {
    background-color: #424242;
    box-shadow: none; }
    @media (max-width: 750px) {
      .Kalend__CalendarHeader-dark {
        border-radius: 0 0 8px 8px !important;
        box-shadow: none; } }
  .Kalend__CalendarHeader-tall {
    height: auto; }
    .Kalend__CalendarHeader-tall-dark {
      background-color: #424242;
      box-shadow: none;
      border-radius: 0; }
  .Kalend__CalendarHeader-small {
    height: 30px; }
  .Kalend__CalendarHeader-day {
    flex-direction: row; }

.Kalend__header_calendar_days__text {
  margin: 0;
  padding: 1px;
  font-size: 1.2em;
  align-self: center;
  text-align: center;
  color: #9e9e9e; }

.Kalend__event_header__container {
  position: absolute;
  border-radius: 4px;
  z-index: 9;
  height: 40px;
  text-align: left;
  padding-left: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0; }

.Kalend__event_header__text, .Kalend__event_header__text--dark {
  color: rgba(255, 255, 255, 0.95);
  font-size: 1em;
  margin: 0;
  text-align: left; }

.Kalend__event_header__text--dark {
  color: #141414; }

.Kalend__CalendarHeaderEvents__container {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  position: relative;
  margin-left: 40px; }

.Kalend__CalendarHeaderEvents__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderEvents__rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  height: auto;
  position: relative; }

.Kalend__CalendarHeaderEvents__eventRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 25px; }

.Kalend__CalendarHeaderEvents__col-wrapper {
  align-self: center;
  align-items: center;
  justify-content: center; }

.Kalend__CalendarHeaderEvents__col {
  height: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center; }

.Kalend__CalendarHeaderCol, .Kalend__CalendarHeaderCol-dark, .Kalend__CalendarHeaderCol-dark-month {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  position: relative; }
  @media (min-width: 750px) {
    .Kalend__CalendarHeaderCol, .Kalend__CalendarHeaderCol-dark, .Kalend__CalendarHeaderCol-dark-month {
      align-items: flex-start; } }
  .Kalend__CalendarHeaderCol-dark {
    background: #424242; }
  .Kalend__CalendarHeaderCol-dark-month {
    background: #1D1F26; }

.Kalend__CalendarHeaderColText__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto; }

.Kalend__CalendarHeaderDates__col {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderDates__primary, .Kalend__CalendarHeaderDates__primary:hover, .Kalend__CalendarHeaderDates__primary-dark, .Kalend__CalendarHeaderDates__primary-dark:hover {
  width: 28px;
  height: 28px; }
  .Kalend__CalendarHeaderDates__primary:hover, .Kalend__CalendarHeaderDates__primary-dark:hover {
    opacity: 1; }
  .Kalend__CalendarHeaderDates__primary-dark:hover {
    opacity: 1; }

.Kalend__CalendarHeaderDates__agenda:hover {
  background: transparent !important;
  cursor: default !important; }

.Kalend__CalendarHeaderDates__agenda-primary:hover {
  cursor: default !important; }

.Kalend__CalendarHeaderDates__circle, .Kalend__CalendarHeaderDates__circle:hover, .Kalend__CalendarHeaderDates__circle-dark, .Kalend__CalendarHeaderDates__circle-dark:hover, .Kalend__CalendarHeaderDates__circle-small, .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1D1F26; }
  .Kalend__CalendarHeaderDates__circle:hover, .Kalend__CalendarHeaderDates__circle-dark:hover, .Kalend__CalendarHeaderDates__circle-small:hover {
    background-color: #e0e0e0; }
  .Kalend__CalendarHeaderDates__circle-dark, .Kalend__CalendarHeaderDates__circle-dark:hover {
    color: #f5f5f5; }
    .Kalend__CalendarHeaderDates__circle-dark:hover {
      background-color: #616161; }
  .Kalend__CalendarHeaderDates__circle-small, .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
    margin-top: 1px;
    height: 20px !important;
    width: 20px !important;
    border-radius: 10px; }
    .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
      background-color: #e0e0e0; }
    .Kalend__CalendarHeaderDates__circle-small-dark:hover {
      background-color: #616161; }

.Kalend__CalendarHeaderDates__text-size-small {
  font-size: 1.1em !important;
  padding: 0.4px; }

.Kalend__CalendarHeaderDates__text, .Kalend__CalendarHeaderDates__text-today, .Kalend__CalendarHeaderDates__text-today-dark {
  margin: 0;
  padding: 1px;
  font-size: 1.5em;
  align-self: center;
  text-align: center;
  font-weight: 500;
  line-height: normal; }
  .Kalend__CalendarHeaderDates__text-today, .Kalend__CalendarHeaderDates__text-today-dark {
    color: #e5e5e5 !important; }
    .Kalend__CalendarHeaderDates__text-today-dark {
      color: rgba(22, 4, 4, 0.87); }

.Kalend__CalendarHeaderWeekDays__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0; }

.Kalend__CalendarHeaderWeekDays__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderWeekDays__col {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center; }

.Kalend__CalendarHeaderWeekDays__text, .Kalend__CalendarHeaderWeekDays__text-dark {
  margin: 0;
  padding: 1px;
  font-size: 1.2em;
  align-self: center;
  text-align: center;
  color: #757575; }
  .Kalend__CalendarHeaderWeekDays__text-dark {
    color: #bdbdbd; }

.Kalend__CalendarHeaderWrapper, .Kalend__CalendarHeaderWrapper-dark {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center; }
  @media (min-width: 750px) {
    .Kalend__CalendarHeaderWrapper, .Kalend__CalendarHeaderWrapper-dark {
      align-items: flex-start; } }
  .Kalend__CalendarHeaderWrapper-dark {
    background: #1e1e1e; }

.Kalend__CalendarViewDropdown__wrapper {
  position: relative; }

.Kalend__CalendarViewDropdown__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.Kalend__CalendarViewDropdown__container, .Kalend__CalendarViewDropdown__container-dark {
  position: absolute;
  top: 50px;
  right: 4px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 16px;
  z-index: 300;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px; }
  .Kalend__CalendarViewDropdown__container-dark {
    background-color: #616161; }

.Kalend__CalendarViewDropdown__container-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.Kalend__carousel__wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll; }
  @media (min-width: 750px) {
    .Kalend__carousel__wrapper {
      overflow-x: hidden; } }

.Kalend__carousel__control, .Kalend__carousel__control-left, .Kalend__carousel__control-right {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; }

.Kalend__carousel__control-left {
  left: 100px;
  justify-content: flex-start;
  animation-name: Kalend__carousel-left;
  animation-duration: 0.3s; }

.Kalend__carousel__control-right {
  right: 100px;
  justify-content: flex-end;
  animation-name: Kalend__carousel-right;
  animation-duration: 0.3s; }

@keyframes Kalend__carousel-left {
  0% {
    left: -100px; }
  100% {
    left: 100px; } }

@keyframes Kalend__carousel-right {
  0% {
    right: -100px; }
  100% {
    right: 100px; } }

.Kalend__full-screen {
  height: 100%;
  width: 100%; }

.Kalend__CalendarBody {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Kalend__calendar-animation {
  animation-name: event-load;
  animation-duration: 1s; }

@keyframes Kalend__event-load {
  0% {
    right: -1500px; }
  100% {
    right: 0; } }

.Kalend__DayViewOneDay, .Kalend__DayViewOneDay-dark {
  position: relative;
  flex-direction: column; }

.Kalend__DayViewOneDay__border-left, .Kalend__DayViewOneDay__border-left-dark {
  border-left: solid 0.6px #e0e0e0; }
  .Kalend__DayViewOneDay__border-left-dark {
    border-left: solid 0.6px #1D1F26; }

.Kalend__CalendarBodyHours__wrapper {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 20px;
  flex-direction: column;
  height: 100%;
  width: 40px;
  pointer-events: none; }

.Kalend__CalendarBodyHours__container {
  flex-direction: row;
  display: flex;
  width: 100%; }

.Kalend__CalendarBodyHours__text, .Kalend__CalendarBodyHours__text-dark {
  position: absolute;
  text-align-vertical: top;
  z-index: 10;
  color: #757575;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: center; }
  .Kalend__CalendarBodyHours__text-dark {
    color: #bdbdbd; }

.Kalend__CalendarBodyHours__line, .Kalend__CalendarBodyHours__line-dark {
  position: absolute;
  left: 40px;
  width: 100vw !important;
  height: 1px;
  background: #e0e0e0;
  z-index: 2; }
  .Kalend__CalendarBodyHours__line-dark {
    background: #616161; }

.Kalend__DesktopLayout {
  display: block; }

.Kalend__Event__animate {
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-name: Kalend__EventDetail_animationShow;
  animation-delay: 0s; }

.Kalend__Event, .Kalend__Event-month, .Kalend__Event-month-dark, .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark, .Kalend__Event-normal, .Kalend__Event-normal-dark, .Kalend__Event-header, .Kalend__Event-header-dark, .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
  border-radius: 6px !important;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  opacity: 1 !important;
  min-height: 6px !important; }
  .Kalend__Event-month, .Kalend__Event-month-dark, .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark {
    padding-left: 1px;
    align-items: center;
    min-height: auto !important;
    border: none;
    white-space: nowrap; }
    .Kalend__Event-month-dark {
      border: solid 1px #1D1F26;
      filter: saturate(60%) brightness(120%); }
  .Kalend__Event-normal, .Kalend__Event-normal-dark {
    border: solid 1px white; }
    .Kalend__Event-normal-dark {
      border: solid 1px #1D1F26;
      filter: saturate(60%) brightness(120%); }
  .Kalend__Event-header, .Kalend__Event-header-dark {
    min-height: 20px !important;
    padding-left: 3px;
    align-items: center;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-name: Kalend__EventDetailHeader_animationShow;
    animation-delay: 0.5s; }
    .Kalend__Event-header-dark {
      filter: saturate(65%) brightness(140%); }
  .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
    min-height: 55px !important;
    justify-content: flex-start;
    align-items: center;
    color: #424242 !important;
    position: relative;
    width: 100%; }
    @media (max-width: 750px) {
      .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
        margin-bottom: 2px; } }
    .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
      filter: saturate(60%) brightness(120%); }
      .Kalend__Event-agenda-dark:hover {
        background: #424242 !important; }
  .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark {
    height: 17px !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 1px !important;
    margin-bottom: 2px !important; }

.Kalend__EventButton__elevation {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4) !important;
  z-index: 9999 !important;
  cursor: grab !important; }

.Kalend__EventButton__resizing_wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 99;
  cursor: n-resize; }

@keyframes Kalend__EventDetailHeader_animationShow {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes Kalend__EventDetail_animationShow {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.Kalend__Event__summary, .Kalend__Event__summary-dark {
  display: -webkit-box;
  webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 0.8em;
  margin: 0;
  padding: 5px 1px 6px 4px;
  opacity: 1;
  overflow: hidden;
  font-weight: 600;
  white-space: inherit; }
  @media (min-width: 750px) {
    .Kalend__Event__summary, .Kalend__Event__summary-dark {
      font-size: 0.8em; } }

.Kalend__Event__summary__type-header, .Kalend__Event__summary__type-header-dark {
  padding: 1px;
  padding-left: 3px;
  font-weight: 500;
  white-space: nowrap; }

.Kalend__Event__summary__type-month, .Kalend__Event__summary__type-showMoreMonth {
  padding: 1px;
  padding-left: 3px;
  font-weight: 500;
  white-space: nowrap; }

.Kalend__Event__summary__type-agenda, .Kalend__Event__summary__type-agenda-dark {
  padding: 8px;
  margin-right: 8px;
  font-weight: 600;
  font-size: 0.8em;
  color: #616161 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  @media (max-width: 750px) {
    .Kalend__Event__summary__type-agenda, .Kalend__Event__summary__type-agenda-dark {
      padding: 0 8px 0 8px; } }
  .Kalend__Event__summary__type-agenda-dark {
    color: #f5f5f5 !important; }

.Kalend__Event__time {
  display: -webkit-box;
  webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  padding: 2px 1px 0 4px;
  opacity: 1;
  overflow: hidden;
  font-size: 0.7em; }

.Kalend__Event__time__type-normal, .Kalend__Event__time__type-normal-dark {
  padding: inherit; }

.Kalend__Event__time__type-header, .Kalend__Event__time__type-header-dark {
  padding: 1px; }
  .Kalend__Event__time__type-header-dark {
    color: #1D1F26; }

.Kalend__Event__time__type-month, .Kalend__Event__time__type-month-dark {
  padding: 1px; }
  .Kalend__Event__time__type-month-dark {
    color: #1D1F26; }

.Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
  padding: 8px;
  font-size: 0.8em;
  color: #616161;
  flex: none; }
  @media (max-width: 750px) {
    .Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
      padding: 0 8px 0 8px;
      margin-top: 4px; } }
  .Kalend__Event__time__type-agenda-dark {
    color: #e0e0e0 !important; }
  @media (max-width: 750px) {
    .Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
      width: 100%; } }

.Kalend__EventShowMoreMonth__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap; }

.Kalend__EventAgenda__container, .Kalend__EventAgenda__container-col {
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: center;
  align-items: center; }
  @media (max-width: 750px) {
    .Kalend__EventAgenda__container, .Kalend__EventAgenda__container-col {
      padding: 0; } }
  .Kalend__EventAgenda__container-col {
    flex-direction: column; }

.Kalend__header_calendar_buttons__container, .Kalend__header_calendar_buttons__container-mobile, .Kalend__header_calendar_buttons__container-mobile-dark, .Kalend__header_calendar_buttons__container-dark {
  border-radius: 8px;
  left: 0;
  padding: 0;
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: solid 0.4px #e0e0e0; }
  .Kalend__header_calendar_buttons__container-mobile, .Kalend__header_calendar_buttons__container-mobile-dark {
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 80px;
    border: none; }
  .Kalend__header_calendar_buttons__container-dark {
    background-color: #616161;
    border: solid 0.4px transparent; }

.Kalend__header_calendar_button, .Kalend__header_calendar_button:hover, .Kalend__header_calendar_button-dark:hover, .Kalend__header_calendar_button-dark, .Kalend__header_calendar_button-mobile, .Kalend__header_calendar_button-mobile-dark, .Kalend__header_calendar_button-mobile-dark:hover, .Kalend__header_calendar_button-mobile:hover, .Kalend__header_calendar_button-selected-mobile:hover, .Kalend__header_calendar_button-selected-mobile-dark:hover, .Kalend__header_calendar_button-selected, .Kalend__header_calendar_button-selected-dark, .Kalend__header_calendar_button-selected-dark:hover, .Kalend__header_calendar_button-selected-mobile, .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected:hover {
  display: flex;
  padding: 6px 4px 6px 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Kalend__header_calendar_button-dark:hover {
    background-color: #424242 !important; }
  .Kalend__header_calendar_button-mobile, .Kalend__header_calendar_button-mobile-dark, .Kalend__header_calendar_button-mobile-dark:hover, .Kalend__header_calendar_button-mobile:hover, .Kalend__header_calendar_button-selected-mobile:hover, .Kalend__header_calendar_button-selected-mobile-dark:hover {
    border: none;
    font-size: 1.5em;
    width: 100%;
    padding: 8px;
    justify-content: flex-start; }
    .Kalend__header_calendar_button-mobile-dark:hover {
      background-color: #757575; }
  .Kalend__header_calendar_button-dark, .Kalend__header_calendar_button-dark:hover {
    background-color: #616161;
    border: #616161 solid 0.5px; }

.Kalend__header_calendar_button-selected-dark, .Kalend__header_calendar_button-selected-dark:hover {
  background-color: #424242;
  border: #424242 solid 0.5px; }
  .Kalend__header_calendar_button-selected-dark:hover {
    background-color: #424242; }

.Kalend__header_calendar_button-selected-mobile, .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected-mobile-dark:hover {
  background: whitesmoke;
  border: none;
  font-size: 1.5em;
  width: 100%;
  padding: 8px;
  justify-content: flex-start; }
  .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected-mobile-dark:hover {
    background: #616161; }
    .Kalend__header_calendar_button-selected-mobile-dark:hover {
      background: #757575; }

.Kalend__header_calendar_button-text, .Kalend__header_calendar_button-text-dark, .Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
  padding: 0 10px 0 10px !important;
  color: #424242; }
  .Kalend__header_calendar_button-text-dark {
    color: #eeeeee; }

.Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
  color: #ec407a !important; }
  .Kalend__header_calendar_button-text-selected-dark {
    color: #f48fb1 !important; }

.Kalend__HeaderCalendarTitle__container, .Kalend__HeaderCalendarTitle__container-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 0 16px;
  width: 100%; }
  .Kalend__HeaderCalendarTitle__container-mobile {
    width: 100%; }

.Kalend__HeaderCalendarTitle, .Kalend__HeaderCalendarTitle-mobile, .Kalend__HeaderCalendarTitle-mobile-dark, .Kalend__HeaderCalendarTitle-dark {
  font-size: 2em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: rgba(22, 4, 4, 0.87);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }
  .Kalend__HeaderCalendarTitle-mobile, .Kalend__HeaderCalendarTitle-mobile-dark {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
    .Kalend__HeaderCalendarTitle-mobile-dark {
      color: #e5e5e5; }
  .Kalend__HeaderCalendarTitle-dark {
    color: #e5e5e5; }

.Kalend__MobileLayout {
  display: block; }

.Kalend__MonthView__container {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  right: 0; }

.Kalend__Monthview_Event {
  max-height: 17px !important;
  min-height: 17px;
  margin-top: 1px; }

.Kalend__MonthView_more_title, .Kalend__MonthView_more_title-dark {
  font-size: 16px;
  padding: 0;
  margin: 4px;
  margin-bottom: 8px;
  text-align: center; }
  .Kalend__MonthView_more_title-dark {
    color: #eeeeee; }

.Kalend__MonthOneDay__header-container {
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.Kalend__MonthOneDay__events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }

.Kalend__MonthOneDay__date-container {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex; }

.Kalend__MonthOneDay__circle-color, .Kalend__MonthOneDay__circle-color-dark {
  background: #ec407a; }
  .Kalend__MonthOneDay__circle-color-dark {
    background: #f48fb1; }

.Kalend__MonthOneDay__date, .Kalend__MonthOneDay__date-dark, .Kalend__MonthOneDay__date-past, .Kalend__MonthOneDay__date-past-dark, .Kalend__MonthOneDay__date-today, .Kalend__MonthOneDay__date-today-dark {
  font-size: 1em;
  padding: 0;
  margin: 0;
  color: #1D1F26; }
  .Kalend__MonthOneDay__date-dark {
    color: #e0e0e0; }
  .Kalend__MonthOneDay__date-past, .Kalend__MonthOneDay__date-past-dark {
    color: #bdbdbd; }
    .Kalend__MonthOneDay__date-past-dark {
      color: #757575; }
  .Kalend__MonthOneDay__date-today, .Kalend__MonthOneDay__date-today-dark {
    color: #e5e5e5; }
    .Kalend__MonthOneDay__date-today-dark {
      color: rgba(22, 4, 4, 0.87); }

.Kalend__MonthOneDay__border, .Kalend__MonthOneDay__border-bottom, .Kalend__MonthOneDay__border-bottom-dark, .Kalend__MonthOneDay__border-bottom-right, .Kalend__MonthOneDay__border-bottom-right-dark, .Kalend__MonthOneDay__border-right, .Kalend__MonthOneDay__border-right-dark {
  width: 14.28571%;
  height: 16.66667%;
  padding: 0;
  margin: 0; }
  .Kalend__MonthOneDay__border-bottom {
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-bottom-dark {
      box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }
  .Kalend__MonthOneDay__border-bottom-right, .Kalend__MonthOneDay__border-bottom-right-dark {
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-bottom-right-dark {
      box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }
  .Kalend__MonthOneDay__border-right {
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-right-dark {
      box-shadow: inset -1px 0px 0px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }

.Kalend__MonthWeekRow__container, .Kalend__MonthWeekRow__container--no-border, .Kalend__MonthWeekRow__container--no-border-dark, .Kalend__MonthWeekRow__container-dark {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-self: stretch;
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #e0e0e0;
  position: relative; }
  .Kalend__MonthWeekRow__container--no-border, .Kalend__MonthWeekRow__container--no-border-dark {
    border-bottom: solid 1px transparent; }
    .Kalend__MonthWeekRow__container--no-border-dark {
      border-bottom: solid 1px transparent; }
  .Kalend__MonthWeekRow__container-dark {
    border-bottom: solid 1px #616161; }

.Kalend__MonthWeekRow__day {
  height: 22px; }

.Kalend__MonthWeekRow__container-events {
  width: 100%;
  height: auto;
  position: relative; }

.Kalend__MonthWeekRow__event {
  max-height: 16px !important;
  min-height: 16px;
  margin-top: 1px; }

.Kalend__ShowMoreModal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; }

.Kalend__ShowMoreModal__container {
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  min-width: 250px;
  overflow-y: scroll;
  max-height: 50vh;
  overflow-x: hidden;
  z-index: 999; }

.Kalend__DaysViewVerticalLine__container {
  width: 100%;
  height: 100%;
  position: relative; }

.Kalend__DaysViewVerticalLine__line, .Kalend__DaysViewVerticalLine__line-dark {
  position: absolute;
  width: 1px;
  background-color: #e0e0e0; }
  .Kalend__DaysViewVerticalLine__line-dark {
    background-color: #616161;
    z-index: 2; }

.Kalend__Dropdown__wrapper {
  position: relative; }

.Kalend__Dropdown__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.Kalend__Dropdown__container, .Kalend__Dropdown__container-dark {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 16px;
  z-index: 20;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px; }
  .Kalend__Dropdown__container-dark {
    box-shadow: none;
    background-color: #616161; }

.Kalend__Dropdown__container-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.Kalend__CurrentHourLine {
  width: 100%;
  position: absolute;
  z-index: 250;
  display: flex;
  flex-direction: row; }

.Kalend__CurrentHourLine__circle, .Kalend__CurrentHourLine__circle-dark {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  left: -2.5px; }

.Kalend__CurrentHourLine__line, .Kalend__CurrentHourLine__line-dark {
  height: 2px;
  width: 100%;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

.Kalend__WeekNumbersCol__wrapper {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: solid 0.4px #eeeeee; }

.Kalend__WeekNumbersCol__container, .Kalend__WeekNumbersCol__container--no-border {
  width: 100%;
  height: 16.66667%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: solid 1px #eeeeee; }
  .Kalend__WeekNumbersCol__container--no-border {
    border-bottom: solid 1px transparent; }

.Kalend__WeekNumbersCol__text, .Kalend__WeekNumbersCol__text-weekdays {
  font-size: 0.5rem;
  color: #757575;
  padding-top: 8px; }
  .Kalend__WeekNumbersCol__text-weekdays {
    padding-top: 0;
    font-size: 0.7rem; }


.Kalend__text {
  padding: 0;
  margin: 0;
  font-family: MONOSPACE, SansSerif, sans-serif, serif;
  font-size: 1em; }

.Kalend__Calendar__root, .Kalend__Calendar__root-dark {
  display: flex;
  width: inherit;
  height: 100%;
  flex-direction: column;
  font-size: 10px;
  overflow-x: hidden; }
  .Kalend__Calendar__root-dark {
    background-color: #1D1F26; }

.Kalend__main * {
  font-family: MONOSPACE, SansSerif, sans-serif, serif; }

.Kalend__Calendar__table, .Kalend__Calendar__table-dark {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  position: relative; }
  .Kalend__Calendar__table-dark {
    background-color: #1D1F26; }

.Kalend__Calendar__header-surface {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: auto; }
  .Kalend__Calendar__header-surface-dark {
    background-color: #1D1F26; }

.Kalend__Calendar__table-surface, .Kalend__Calendar__header-surface-dark, .Kalend__Calendar__table-surface-dark {
  display: inherit;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  position: relative; }
  .Kalend__Calendar__table-surface-dark {
    background-color: #1D1F26; }

.Kalend__button {
  padding: inherit;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  appearance: auto;
  letter-spacing: inherit;
  word-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  text-indent: inherit;
  text-shadow: none;
  display: flex;
  text-align: inherit;
  align-items: inherit;
  box-sizing: inherit;
  background-color: transparent;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  border-image: inherit; }

.Kalend__text-light {
  color: #f5f5f5; }

.Kalend__text-dark {
  color: #1D1F26; }

.Kalend__color-text-primary {
  color: #ec407a; }
  .Kalend__color-text-primary-dark {
    color: #f48fb1; }

.Kalend__color-background-primary {
  color: #ec407a; }
  .Kalend__color-background-primary-dark {
    color: #f48fb1; }

.Kalend__color-text-gray300 {
  color: #e0e0e0; }
  .Kalend__color-text-gray300-dark {
    color: #616161; }

.Kalend__color-text-gray800 {
  color: #424242; }
  .Kalend__color-text-gray800-dark {
    color: #eeeeee; }

.Kalend__color-text-base {
  color: #1D1F26; }
  .Kalend__color-text-base-dark {
    color: #eeeeee; }
  .Kalend__color-text-base-grayed {
    color: #757575; }
    .Kalend__color-text-base-grayed-dark {
      color: #bdbdbd; }

.Kalend__CalendarDesktopNavigation__container, .Kalend__CalendarDesktopNavigation__container-mobile, .Kalend__CalendarDesktopNavigation__container-mobile-dark, .Kalend__CalendarDesktopNavigation__container-dark {
  border-radius: 8px;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  height: 70px;
  max-height: 70px;
  min-height: 70px;
  width: auto;
  padding-left: 12px;
  align-items: center; }
  .Kalend__CalendarDesktopNavigation__container-mobile, .Kalend__CalendarDesktopNavigation__container-mobile-dark {
    flex-direction: column;
    min-height: 0; }
    .Kalend__CalendarDesktopNavigation__container-mobile-dark {
      background: #424242;
      border-radius: 0; }

.Kalend__CalendarDesktopNavigation__container-dark {
  background-color: #424242;
  border-radius: 0; }

.Kalend__CalendarDesktopNavigation__buttons, .Kalend__CalendarDesktopNavigation__buttons-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .Kalend__CalendarDesktopNavigation__buttons-mobile {
    justify-content: flex-end; }

.Kalend__calendarButtonToday {
  display: flex;
  padding: 6px 4px 6px 4px;
  justify-content: center;
  align-items: center;
  width: 60px;
  border: solid 0.4px #e0e0e0;
  margin-right: 12px;
  margin-left: 12px; }

.Kalend__Agenda__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }

.Kalend__Agenda__container-empty, .Kalend__Agenda__container-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

.Kalend__Agenda__text-empty, .Kalend__Agenda__text-empty-dark {
  color: #1D1F26;
  font-size: 1.5em;
  text-align: center; }
  .Kalend__Agenda__text-empty-dark {
    color: #f5f5f5; }

.Kalend__ButtonBase, .Kalend__ButtonBase-dark, .Kalend__ButtonBase-dark:hover, .Kalend__ButtonBase-normal, .Kalend__ButtonBase-normal:hover, .Kalend__ButtonBase-disabled, .Kalend__ButtonBase-primary, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark, .Kalend__ButtonBase-transparent, .Kalend__ButtonBase-border, .Kalend__ButtonBase-border-dark {
  min-height: 20px;
  font-size: 1.6em;
  border-radius: 10px;
  font-weight: 400;
  border: none;
  outline-style: none;
  cursor: pointer;
  color: #424242;
  background-color: transparent;
  position: relative;
  overflow: hidden; }
  .Kalend__ButtonBase:hover, .Kalend__ButtonBase-dark:hover, .Kalend__ButtonBase-normal:hover, .Kalend__ButtonBase-disabled:hover, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-transparent:hover, .Kalend__ButtonBase-border:hover, .Kalend__ButtonBase-border-dark:hover {
    background-color: #eeeeee; }
  .Kalend__ButtonBase-dark, .Kalend__ButtonBase-dark:hover {
    color: #f5f5f5; }
    .Kalend__ButtonBase-dark:hover {
      background-color: #616161;
      color: #f5f5f5; }
  .Kalend__ButtonBase-normal, .Kalend__ButtonBase-normal:hover {
    border: none;
    background-color: #424242;
    padding: 4px 12px 4px 12px;
    color: #f5f5f5; }
    .Kalend__ButtonBase-normal:hover {
      background-color: #757575; }
  .Kalend__ButtonBase-disabled {
    background-color: #bdbdbd;
    color: #616161; }
  .Kalend__ButtonBase-primary, .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark {
    padding: 4px 12px 4px 12px;
    background-color: #ec407a;
    color: #eeeeee; }
    .Kalend__ButtonBase-primary:hover, .Kalend__ButtonBase-primary-dark:hover {
      background-color: rgba(236, 64, 122, 0.7); }
    .Kalend__ButtonBase-primary-dark {
      background-color: #f48fb1;
      color: #424242; }
  .Kalend__ButtonBase-transparent {
    padding: 4px 12px 4px 12px;
    color: #616161;
    background-color: transparent; }
    .Kalend__ButtonBase-transparent:hover {
      background-color: #eeeeee; }

.Kalend__ButtonBase__animation {
  position: absolute;
  transform: scale(0);
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 50%;
  animation: Kalend__ripple__animation 500ms ease-in; }

@keyframes Kalend__ripple__animation {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.Kalend__ButtonBase__loader-circle, .Kalend__ButtonBase__loader-circle-left, .Kalend__ButtonBase__loader-circle-middle, .Kalend__ButtonBase__loader-circle-right {
  width: 12px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 6px;
  margin: 0 6px 0 6px;
  opacity: 0;
  animation-iteration-count: infinite;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-name: Kalend__ButtonBase__animation-pulse-loader;
  animation-delay: 0s; }
  .Kalend__ButtonBase__loader-circle-left {
    animation-delay: 0s; }
  .Kalend__ButtonBase__loader-circle-middle {
    animation-delay: 0.3s; }
  .Kalend__ButtonBase__loader-circle-right {
    animation-delay: 0.5s; }

@keyframes Kalend__ButtonBase__animation-pulse-loader {
  0% {
    opacity: 0;
    background-color: #9e9e9e; }
  50% {
    opacity: 1;
    background-color: #e0e0e0; }
  100% {
    opacity: 0; } }

.Kalend__Button-long, .Kalend__Button-long:hover {
  width: 100%;
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.Kalend__ButtonBase-border, .Kalend__ButtonBase-border-dark {
  display: flex;
  padding: 6px 8px 6px 8px;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  width: auto;
  border: solid 0.4px #e0e0e0;
  margin-right: 12px;
  margin-left: 12px; }
  .Kalend__ButtonBase-border-dark {
    border: solid 0.4px #9e9e9e;
    color: #eeeeee; }
    .Kalend__ButtonBase-border-dark:hover {
      border: solid 0.4px #757575;
      background-color: #757575; }

.Kalend__ButtonIcon__container, .Kalend__ButtonIcon__container-small, .Kalend__ButtonIcon__container-big, .Kalend__ButtonIcon__container-full {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative; }
  .Kalend__ButtonIcon__container-small {
    width: 30px;
    height: 30px;
    border-radius: 15px; }
  .Kalend__ButtonIcon__container-big {
    width: 55px;
    height: 55px;
    border-radius: 27.5px; }
  .Kalend__ButtonIcon__container-full {
    width: 100%;
    height: 100%;
    border-radius: 50%; }

.Kalend__ButtonIcon, .Kalend__ButtonIcon:hover, .Kalend__ButtonIcon-dark, .Kalend__ButtonIcon-dark:hover, .Kalend__ButtonIcon-inactive, .Kalend__ButtonIcon-inactive:active, .Kalend__ButtonIcon-inactive:hover {
  fill: #757575;
  background-color: transparent;
  font-size: 1.4em;
  border: none;
  outline-style: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 27.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; }
  .Kalend__ButtonIcon:hover, .Kalend__ButtonIcon-dark:hover, .Kalend__ButtonIcon-inactive:hover {
    opacity: 0.9;
    background-color: #e0e0e0; }
  .Kalend__ButtonIcon-dark, .Kalend__ButtonIcon-dark:hover {
    fill: #bdbdbd; }
    .Kalend__ButtonIcon-dark:hover {
      opacity: 0.9;
      background-color: #616161; }
  .Kalend__ButtonIcon-inactive:active {
    background-color: transparent;
    animation: none; }
  .Kalend__ButtonIcon-inactive:hover {
    background-color: transparent; }

.Kalend__ButtonIcon__disabled {
  cursor: default !important; }
  .Kalend__ButtonIcon__disabled:hover {
    background-color: transparent !important; }
  .Kalend__ButtonIcon__disabled:active {
    background-color: transparent !important; }

.Kalend__ButtonIcon__svg, .Kalend__ButtonIcon__svg-normal, .Kalend__ButtonIcon__svg-normal-dark, .Kalend__ButtonIcon__svg-dark {
  width: 100%;
  height: 100%;
  padding: 1px;
  z-index: 5;
  fill: #757575; }
  .Kalend__ButtonIcon__svg-normal, .Kalend__ButtonIcon__svg-normal-dark {
    width: 2.2em;
    height: 2.2em; }
    .Kalend__ButtonIcon__svg-normal-dark {
      fill: #e0e0e0; }
  .Kalend__ButtonIcon__svg-dark {
    fill: #e0e0e0; }

.Kalend__ButtonIcon__backdrop, .Kalend__ButtonIcon__backdrop:active {
  position: absolute;
  background-color: #bdbdbd;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-name: Kalend__ButtonIcon__animation-backdrop;
  animation-delay: 0s;
  animation-duration: 0.4s; }
  .Kalend__ButtonIcon__backdrop:active {
    background-color: #757575;
    animation-name: Kalend__ButtonIcon__animation-press;
    animation-duration: 0.1s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards; }

@keyframes Kalend__ButtonIcon__animation-press {
  0% {
    background-color: #f5f5f5;
    opacity: 0.5; }
  100% {
    background-color: #e0e0e0;
    opacity: 1; } }

@keyframes Kalend__ButtonIcon__animation-backdrop {
  0% {
    background-color: #f5f5f5;
    width: 0;
    height: 0; }
  100% {
    background-color: #bdbdbd;
    width: 100%;
    height: 100%; } }

.Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
  border-radius: 8px;
  left: 0;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  width: 100%;
  padding-bottom: 6px;
  box-shadow: 0 5px 5px -5px #333;
  z-index: 10; }
  @media (max-width: 750px) {
    .Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
      border-radius: 0px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14); } }
  .Kalend__CalendarHeader-dark {
    background-color: #424242;
    box-shadow: none; }
    @media (max-width: 750px) {
      .Kalend__CalendarHeader-dark {
        border-radius: 0 0 8px 8px !important;
        box-shadow: none; } }
  .Kalend__CalendarHeader-tall {
    height: auto; }
    .Kalend__CalendarHeader-tall-dark {
      background-color: #424242;
      box-shadow: none;
      border-radius: 0; }
  .Kalend__CalendarHeader-small {
    height: 30px; }
  .Kalend__CalendarHeader-day {
    flex-direction: row; }

.Kalend__header_calendar_days__text {
  margin: 0;
  padding: 1px;
  font-size: 1.2em;
  align-self: center;
  text-align: center;
  color: #9e9e9e; }

.Kalend__event_header__container {
  position: absolute;
  border-radius: 4px;
  z-index: 9;
  height: 40px;
  text-align: left;
  padding-left: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0; }

.Kalend__event_header__text, .Kalend__event_header__text--dark {
  color: rgba(255, 255, 255, 0.95);
  font-size: 1em;
  margin: 0;
  text-align: left; }

.Kalend__event_header__text--dark {
  color: #141414; }

.Kalend__CalendarViewDropdown__wrapper {
  position: relative; }

.Kalend__CalendarViewDropdown__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.Kalend__CalendarViewDropdown__container, .Kalend__CalendarViewDropdown__container-dark {
  position: absolute;
  top: 50px;
  right: 4px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 16px;
  z-index: 300;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px; }
  .Kalend__CalendarViewDropdown__container-dark {
    background-color: #616161; }

.Kalend__CalendarViewDropdown__container-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.Kalend__carousel__wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll; }
  @media (min-width: 750px) {
    .Kalend__carousel__wrapper {
      overflow-x: hidden; } }

.Kalend__carousel__control, .Kalend__carousel__control-left, .Kalend__carousel__control-right {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; }

.Kalend__carousel__control-left {
  left: 100px;
  justify-content: flex-start;
  animation-name: Kalend__carousel-left;
  animation-duration: 0.3s; }

.Kalend__carousel__control-right {
  right: 100px;
  justify-content: flex-end;
  animation-name: Kalend__carousel-right;
  animation-duration: 0.3s; }

@keyframes Kalend__carousel-left {
  0% {
    left: -100px; }
  100% {
    left: 100px; } }

@keyframes Kalend__carousel-right {
  0% {
    right: -100px; }
  100% {
    right: 100px; } }

.Kalend__CurrentHourLine {
  width: 100%;
  position: absolute;
  z-index: 250;
  display: flex;
  flex-direction: row; }

.Kalend__CurrentHourLine__circle, .Kalend__CurrentHourLine__circle-dark {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  left: -2.5px; }

.Kalend__CurrentHourLine__line, .Kalend__CurrentHourLine__line-dark {
  height: 2px;
  width: 100%;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

.Kalend__full-screen {
  height: 100%;
  width: 100%; }

.Kalend__CalendarBody {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.Kalend__calendar-animation {
  animation-name: event-load;
  animation-duration: 1s; }

@keyframes Kalend__event-load {
  0% {
    right: -1500px; }
  100% {
    right: 0; } }

.Kalend__DesktopLayout {
  display: block; }

.Kalend__Dropdown__wrapper {
  position: relative; }

.Kalend__Dropdown__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.Kalend__Dropdown__container, .Kalend__Dropdown__container-dark {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 16px;
  z-index: 20;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px; }
  .Kalend__Dropdown__container-dark {
    box-shadow: none;
    background-color: #616161; }

.Kalend__Dropdown__container-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.Kalend__Event__animate {
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-name: Kalend__EventDetail_animationShow;
  animation-delay: 0s; }

.Kalend__Event, .Kalend__Event-month, .Kalend__Event-month-dark, .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark, .Kalend__Event-normal, .Kalend__Event-normal-dark, .Kalend__Event-header, .Kalend__Event-header-dark, .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
  border-radius: 6px !important;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  opacity: 1 !important;
  min-height: 6px !important; }
  .Kalend__Event-month, .Kalend__Event-month-dark, .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark {
    padding-left: 1px;
    align-items: center;
    min-height: auto !important;
    border: none;
    white-space: nowrap; }
    .Kalend__Event-month-dark {
      border: solid 1px #1D1F26;
      filter: saturate(60%) brightness(120%); }
  .Kalend__Event-normal, .Kalend__Event-normal-dark {
    border: solid 1px white; }
    .Kalend__Event-normal-dark {
      border: solid 1px #1D1F26;
      filter: saturate(60%) brightness(120%); }
  .Kalend__Event-header, .Kalend__Event-header-dark {
    min-height: 20px !important;
    padding-left: 3px;
    align-items: center;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-name: Kalend__EventDetailHeader_animationShow;
    animation-delay: 0.5s; }
    .Kalend__Event-header-dark {
      filter: saturate(65%) brightness(140%); }
  .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
    min-height: 55px !important;
    justify-content: flex-start;
    align-items: center;
    color: #424242 !important;
    position: relative;
    width: 100%; }
    @media (max-width: 750px) {
      .Kalend__Event-agenda, .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
        margin-bottom: 2px; } }
    .Kalend__Event-agenda-dark, .Kalend__Event-agenda-dark:hover {
      filter: saturate(60%) brightness(120%); }
      .Kalend__Event-agenda-dark:hover {
        background: #424242 !important; }
  .Kalend__Event-showMoreMonth, .Kalend__Event-showMoreMonth-dark {
    height: 17px !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 1px !important;
    margin-bottom: 2px !important; }

.Kalend__EventButton__elevation {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4) !important;
  z-index: 9999 !important;
  cursor: grab !important; }

.Kalend__EventButton__resizing_wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 99;
  cursor: n-resize; }

@keyframes Kalend__EventDetailHeader_animationShow {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes Kalend__EventDetail_animationShow {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.Kalend__header_calendar_buttons__container, .Kalend__header_calendar_buttons__container-mobile, .Kalend__header_calendar_buttons__container-mobile-dark, .Kalend__header_calendar_buttons__container-dark {
  border-radius: 8px;
  left: 0;
  padding: 0;
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: solid 0.4px #e0e0e0; }
  .Kalend__header_calendar_buttons__container-mobile, .Kalend__header_calendar_buttons__container-mobile-dark {
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 80px;
    border: none; }
  .Kalend__header_calendar_buttons__container-dark {
    background-color: #616161;
    border: solid 0.4px transparent; }

.Kalend__header_calendar_button, .Kalend__header_calendar_button:hover, .Kalend__header_calendar_button-dark:hover, .Kalend__header_calendar_button-dark, .Kalend__header_calendar_button-mobile, .Kalend__header_calendar_button-mobile-dark, .Kalend__header_calendar_button-mobile-dark:hover, .Kalend__header_calendar_button-mobile:hover, .Kalend__header_calendar_button-selected-mobile:hover, .Kalend__header_calendar_button-selected-mobile-dark:hover, .Kalend__header_calendar_button-selected, .Kalend__header_calendar_button-selected-dark, .Kalend__header_calendar_button-selected-dark:hover, .Kalend__header_calendar_button-selected-mobile, .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected:hover {
  display: flex;
  padding: 6px 4px 6px 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Kalend__header_calendar_button-dark:hover {
    background-color: #424242 !important; }
  .Kalend__header_calendar_button-mobile, .Kalend__header_calendar_button-mobile-dark, .Kalend__header_calendar_button-mobile-dark:hover, .Kalend__header_calendar_button-mobile:hover, .Kalend__header_calendar_button-selected-mobile:hover, .Kalend__header_calendar_button-selected-mobile-dark:hover {
    border: none;
    font-size: 1.5em;
    width: 100%;
    padding: 8px;
    justify-content: flex-start; }
    .Kalend__header_calendar_button-mobile-dark:hover {
      background-color: #757575; }
  .Kalend__header_calendar_button-dark, .Kalend__header_calendar_button-dark:hover {
    background-color: #616161;
    border: #616161 solid 0.5px; }

.Kalend__header_calendar_button-selected-dark, .Kalend__header_calendar_button-selected-dark:hover {
  background-color: #424242;
  border: #424242 solid 0.5px; }
  .Kalend__header_calendar_button-selected-dark:hover {
    background-color: #424242; }

.Kalend__header_calendar_button-selected-mobile, .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected-mobile-dark:hover {
  background: whitesmoke;
  border: none;
  font-size: 1.5em;
  width: 100%;
  padding: 8px;
  justify-content: flex-start; }
  .Kalend__header_calendar_button-selected-mobile-dark, .Kalend__header_calendar_button-selected-mobile-dark:hover {
    background: #616161; }
    .Kalend__header_calendar_button-selected-mobile-dark:hover {
      background: #757575; }

.Kalend__header_calendar_button-text, .Kalend__header_calendar_button-text-dark, .Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
  padding: 0 10px 0 10px !important;
  color: #424242; }
  .Kalend__header_calendar_button-text-dark {
    color: #eeeeee; }

.Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
  color: #ec407a !important; }
  .Kalend__header_calendar_button-text-selected-dark {
    color: #f48fb1 !important; }

.Kalend__HeaderCalendarTitle__container, .Kalend__HeaderCalendarTitle__container-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 0 16px;
  width: 100%; }
  .Kalend__HeaderCalendarTitle__container-mobile {
    width: 100%; }

.Kalend__HeaderCalendarTitle, .Kalend__HeaderCalendarTitle-mobile, .Kalend__HeaderCalendarTitle-mobile-dark, .Kalend__HeaderCalendarTitle-dark {
  font-size: 2em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: rgba(22, 4, 4, 0.87);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }
  .Kalend__HeaderCalendarTitle-mobile, .Kalend__HeaderCalendarTitle-mobile-dark {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
    .Kalend__HeaderCalendarTitle-mobile-dark {
      color: #e5e5e5; }
  .Kalend__HeaderCalendarTitle-dark {
    color: #e5e5e5; }

.Kalend__MobileLayout {
  display: block; }

.Kalend__MonthView__container {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  right: 0; }

.Kalend__Monthview_Event {
  max-height: 17px !important;
  min-height: 17px;
  margin-top: 1px; }

.Kalend__MonthView_more_title, .Kalend__MonthView_more_title-dark {
  font-size: 16px;
  padding: 0;
  margin: 4px;
  margin-bottom: 8px;
  text-align: center; }
  .Kalend__MonthView_more_title-dark {
    color: #eeeeee; }

.Kalend__WeekNumbersCol__wrapper {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: solid 0.4px #eeeeee; }

.Kalend__WeekNumbersCol__container, .Kalend__WeekNumbersCol__container--no-border {
  width: 100%;
  height: 16.66667%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: solid 1px #eeeeee; }
  .Kalend__WeekNumbersCol__container--no-border {
    border-bottom: solid 1px transparent; }

.Kalend__WeekNumbersCol__text, .Kalend__WeekNumbersCol__text-weekdays {
  font-size: 0.5rem;
  color: #757575;
  padding-top: 8px; }
  .Kalend__WeekNumbersCol__text-weekdays {
    padding-top: 0;
    font-size: 0.7rem; }

.Kalend__AgendaDayRow__container, .Kalend__AgendaDayRow__container-dark {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid #e0e0e0 0.4px; }
  .Kalend__AgendaDayRow__container-dark {
    border-bottom: solid #616161 0.4px; }

.Kalend__AgendaDayRow__events {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.Kalend__AgendaDayRow__container-day {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: auto; }

.Kalend__CalendarHeaderEvents__container {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  position: relative;
  margin-left: 40px; }

.Kalend__CalendarHeaderEvents__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderEvents__rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  height: auto;
  position: relative; }

.Kalend__CalendarHeaderEvents__eventRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 25px; }

.Kalend__CalendarHeaderEvents__col-wrapper {
  align-self: center;
  align-items: center;
  justify-content: center; }

.Kalend__CalendarHeaderEvents__col {
  height: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center; }

.Kalend__DayViewOneDay, .Kalend__DayViewOneDay-dark {
  position: relative;
  flex-direction: column; }

.Kalend__DayViewOneDay__border-left, .Kalend__DayViewOneDay__border-left-dark {
  border-left: solid 0.6px #e0e0e0; }
  .Kalend__DayViewOneDay__border-left-dark {
    border-left: solid 0.6px #1D1F26; }

.Kalend__DaysViewVerticalLine__container {
  width: 100%;
  height: 100%;
  position: relative; }

.Kalend__DaysViewVerticalLine__line, .Kalend__DaysViewVerticalLine__line-dark {
  position: absolute;
  width: 1px;
  background-color: #e0e0e0; }
  .Kalend__DaysViewVerticalLine__line-dark {
    background-color: #616161;
    z-index: 2; }

.Kalend__EventAgenda__container, .Kalend__EventAgenda__container-col {
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: center;
  align-items: center; }
  @media (max-width: 750px) {
    .Kalend__EventAgenda__container, .Kalend__EventAgenda__container-col {
      padding: 0; } }
  .Kalend__EventAgenda__container-col {
    flex-direction: column; }

.Kalend__EventShowMoreMonth__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap; }

.Kalend__MonthOneDay__header-container {
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.Kalend__MonthOneDay__events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }

.Kalend__MonthOneDay__date-container {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex; }

.Kalend__MonthOneDay__circle-color, .Kalend__MonthOneDay__circle-color-dark {
  background: #ec407a; }
  .Kalend__MonthOneDay__circle-color-dark {
    background: #f48fb1; }

.Kalend__MonthOneDay__date, .Kalend__MonthOneDay__date-dark, .Kalend__MonthOneDay__date-past, .Kalend__MonthOneDay__date-past-dark, .Kalend__MonthOneDay__date-today, .Kalend__MonthOneDay__date-today-dark {
  font-size: 1em;
  padding: 0;
  margin: 0;
  color: #1D1F26; }
  .Kalend__MonthOneDay__date-dark {
    color: #e0e0e0; }
  .Kalend__MonthOneDay__date-past, .Kalend__MonthOneDay__date-past-dark {
    color: #bdbdbd; }
    .Kalend__MonthOneDay__date-past-dark {
      color: #757575; }
  .Kalend__MonthOneDay__date-today, .Kalend__MonthOneDay__date-today-dark {
    color: #e5e5e5; }
    .Kalend__MonthOneDay__date-today-dark {
      color: rgba(22, 4, 4, 0.87); }

.Kalend__MonthOneDay__border, .Kalend__MonthOneDay__border-bottom, .Kalend__MonthOneDay__border-bottom-dark, .Kalend__MonthOneDay__border-bottom-right, .Kalend__MonthOneDay__border-bottom-right-dark, .Kalend__MonthOneDay__border-right, .Kalend__MonthOneDay__border-right-dark {
  width: 14.28571%;
  height: 16.66667%;
  padding: 0;
  margin: 0; }
  .Kalend__MonthOneDay__border-bottom {
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-bottom-dark {
      box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }
  .Kalend__MonthOneDay__border-bottom-right, .Kalend__MonthOneDay__border-bottom-right-dark {
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-bottom-right-dark {
      box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }
  .Kalend__MonthOneDay__border-right {
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.1); }
    .Kalend__MonthOneDay__border-right-dark {
      box-shadow: inset -1px 0px 0px rgba(255, 255, 255, 0.05);
      -moz-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05); }

.Kalend__MonthWeekRow__container, .Kalend__MonthWeekRow__container--no-border, .Kalend__MonthWeekRow__container--no-border-dark, .Kalend__MonthWeekRow__container-dark {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-self: stretch;
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #e0e0e0;
  position: relative; }
  .Kalend__MonthWeekRow__container--no-border, .Kalend__MonthWeekRow__container--no-border-dark {
    border-bottom: solid 1px transparent; }
    .Kalend__MonthWeekRow__container--no-border-dark {
      border-bottom: solid 1px transparent; }
  .Kalend__MonthWeekRow__container-dark {
    border-bottom: solid 1px #616161; }

.Kalend__MonthWeekRow__day {
  height: 22px; }

.Kalend__MonthWeekRow__container-events {
  width: 100%;
  height: auto;
  position: relative; }

.Kalend__MonthWeekRow__event {
  max-height: 16px !important;
  min-height: 16px;
  margin-top: 1px; }

.Kalend__ShowMoreModal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; }

.Kalend__ShowMoreModal__container {
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  min-width: 250px;
  overflow-y: scroll;
  max-height: 50vh;
  overflow-x: hidden;
  z-index: 999; }

.Kalend__CalendarHeaderCol, .Kalend__CalendarHeaderCol-dark, .Kalend__CalendarHeaderCol-dark-month {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  position: relative; }
  @media (min-width: 750px) {
    .Kalend__CalendarHeaderCol, .Kalend__CalendarHeaderCol-dark, .Kalend__CalendarHeaderCol-dark-month {
      align-items: flex-start; } }
  .Kalend__CalendarHeaderCol-dark {
    background: #424242; }
  .Kalend__CalendarHeaderCol-dark-month {
    background: #1D1F26; }

.Kalend__CalendarHeaderColText__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto; }

.Kalend__CalendarHeaderDates__col {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderDates__primary, .Kalend__CalendarHeaderDates__primary:hover, .Kalend__CalendarHeaderDates__primary-dark, .Kalend__CalendarHeaderDates__primary-dark:hover {
  width: 28px;
  height: 28px; }
  .Kalend__CalendarHeaderDates__primary:hover, .Kalend__CalendarHeaderDates__primary-dark:hover {
    opacity: 1; }
  .Kalend__CalendarHeaderDates__primary-dark:hover {
    opacity: 1; }

.Kalend__CalendarHeaderDates__agenda:hover {
  background: transparent !important;
  cursor: default !important; }

.Kalend__CalendarHeaderDates__agenda-primary:hover {
  cursor: default !important; }

.Kalend__CalendarHeaderDates__circle, .Kalend__CalendarHeaderDates__circle:hover, .Kalend__CalendarHeaderDates__circle-dark, .Kalend__CalendarHeaderDates__circle-dark:hover, .Kalend__CalendarHeaderDates__circle-small, .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1D1F26; }
  .Kalend__CalendarHeaderDates__circle:hover, .Kalend__CalendarHeaderDates__circle-dark:hover, .Kalend__CalendarHeaderDates__circle-small:hover {
    background-color: #e0e0e0; }
  .Kalend__CalendarHeaderDates__circle-dark, .Kalend__CalendarHeaderDates__circle-dark:hover {
    color: #f5f5f5; }
    .Kalend__CalendarHeaderDates__circle-dark:hover {
      background-color: #616161; }
  .Kalend__CalendarHeaderDates__circle-small, .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
    margin-top: 1px;
    height: 20px !important;
    width: 20px !important;
    border-radius: 10px; }
    .Kalend__CalendarHeaderDates__circle-small:hover, .Kalend__CalendarHeaderDates__circle-small-dark:hover {
      background-color: #e0e0e0; }
    .Kalend__CalendarHeaderDates__circle-small-dark:hover {
      background-color: #616161; }

.Kalend__CalendarHeaderDates__text-size-small {
  font-size: 1.1em !important;
  padding: 0.4px; }

.Kalend__CalendarHeaderDates__text, .Kalend__CalendarHeaderDates__text-today, .Kalend__CalendarHeaderDates__text-today-dark {
  margin: 0;
  padding: 1px;
  font-size: 1.5em;
  align-self: center;
  text-align: center;
  font-weight: 500;
  line-height: normal; }
  .Kalend__CalendarHeaderDates__text-today, .Kalend__CalendarHeaderDates__text-today-dark {
    color: #e5e5e5 !important; }
    .Kalend__CalendarHeaderDates__text-today-dark {
      color: rgba(22, 4, 4, 0.87); }

.Kalend__CalendarHeaderWeekDays__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0; }

.Kalend__CalendarHeaderWeekDays__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.Kalend__CalendarHeaderWeekDays__col {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center; }

.Kalend__CalendarHeaderWeekDays__text, .Kalend__CalendarHeaderWeekDays__text-dark {
  margin: 0;
  padding: 1px;
  font-size: 1.2em;
  align-self: center;
  text-align: center;
  color: #757575; }
  .Kalend__CalendarHeaderWeekDays__text-dark {
    color: #bdbdbd; }

.Kalend__CalendarHeaderWrapper, .Kalend__CalendarHeaderWrapper-dark {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center; }
  @media (min-width: 750px) {
    .Kalend__CalendarHeaderWrapper, .Kalend__CalendarHeaderWrapper-dark {
      align-items: flex-start; } }
  .Kalend__CalendarHeaderWrapper-dark {
    background: #1e1e1e; }

.Kalend__CalendarBodyHours__wrapper {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 20px;
  flex-direction: column;
  height: 100%;
  width: 40px;
  pointer-events: none; }

.Kalend__CalendarBodyHours__container {
  flex-direction: row;
  display: flex;
  width: 100%; }

.Kalend__CalendarBodyHours__text, .Kalend__CalendarBodyHours__text-dark {
  position: absolute;
  text-align-vertical: top;
  z-index: 10;
  color: #757575;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  text-align: center; }
  .Kalend__CalendarBodyHours__text-dark {
    color: #bdbdbd; }

.Kalend__CalendarBodyHours__line, .Kalend__CalendarBodyHours__line-dark {
  position: absolute;
  left: 40px;
  width: 100vw !important;
  height: 1px;
  background: #e0e0e0;
  z-index: 2; }
  .Kalend__CalendarBodyHours__line-dark {
    background: #616161; }

.Kalend__Event__summary, .Kalend__Event__summary-dark {
  display: -webkit-box;
  webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 0.8em;
  margin: 0;
  padding: 5px 1px 6px 4px;
  opacity: 1;
  overflow: hidden;
  font-weight: 600;
  white-space: inherit; }
  @media (min-width: 750px) {
    .Kalend__Event__summary, .Kalend__Event__summary-dark {
      font-size: 0.8em; } }

.Kalend__Event__summary__type-header, .Kalend__Event__summary__type-header-dark {
  padding: 1px;
  padding-left: 3px;
  font-weight: 500;
  white-space: nowrap; }

.Kalend__Event__summary__type-month, .Kalend__Event__summary__type-showMoreMonth {
  padding: 1px;
  padding-left: 3px;
  font-weight: 500;
  white-space: nowrap; }

.Kalend__Event__summary__type-agenda, .Kalend__Event__summary__type-agenda-dark {
  padding: 8px;
  margin-right: 8px;
  font-weight: 600;
  font-size: 0.8em;
  color: #616161 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  @media (max-width: 750px) {
    .Kalend__Event__summary__type-agenda, .Kalend__Event__summary__type-agenda-dark {
      padding: 0 8px 0 8px; } }
  .Kalend__Event__summary__type-agenda-dark {
    color: #f5f5f5 !important; }

.Kalend__Event__time {
  display: -webkit-box;
  webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  padding: 2px 1px 0 4px;
  opacity: 1;
  overflow: hidden;
  font-size: 0.7em; }

.Kalend__Event__time__type-normal, .Kalend__Event__time__type-normal-dark {
  padding: inherit; }

.Kalend__Event__time__type-header, .Kalend__Event__time__type-header-dark {
  padding: 1px; }
  .Kalend__Event__time__type-header-dark {
    color: #1D1F26; }

.Kalend__Event__time__type-month, .Kalend__Event__time__type-month-dark {
  padding: 1px; }
  .Kalend__Event__time__type-month-dark {
    color: #1D1F26; }

.Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
  padding: 8px;
  font-size: 0.8em;
  color: #616161;
  flex: none; }
  @media (max-width: 750px) {
    .Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
      padding: 0 8px 0 8px;
      margin-top: 4px; } }
  .Kalend__Event__time__type-agenda-dark {
    color: #e0e0e0 !important; }
  @media (max-width: 750px) {
    .Kalend__Event__time__type-agenda, .Kalend__Event__time__type-agenda-dark {
      width: 100%; } }
