.p-order-0 {
    order: 0;
}

.p-order-1 {
    order: 1;
}

.p-order-2 {
    order: 2;
}

.p-order-3 {
    order: 3;
}

.p-order-4 {
    order: 4;
}

.p-order-5 {
    order: 5;
}

.p-order-6 {
    order: 6;
}

@media screen and (min-width: $sm) {
    .p-order-sm-0 {
        order: 0;
    }
    
    .p-order-sm-1 {
        order: 1;
    }
    
    .p-order-sm-2 {
        order: 2;
    }
    
    .p-order-sm-3 {
        order: 3;
    }
    
    .p-order-sm-4 {
        order: 4;
    }
    
    .p-order-sm-5 {
        order: 5;
    }
    
    .p-order-sm-6 {
        order: 6;
    }
}

@media screen and (min-width: $md) {
    .p-order-md-0 {
        order: 0;
    }
    
    .p-order-md-1 {
        order: 1;
    }
    
    .p-order-md-2 {
        order: 2;
    }
    
    .p-order-md-3 {
        order: 3;
    }
    
    .p-order-md-4 {
        order: 4;
    }
    
    .p-order-md-5 {
        order: 5;
    }
    
    .p-order-md-6 {
        order: 6;
    }
}

@media screen and (min-width: $lg) {
    .p-order-lg-0 {
        order: 0;
    }
    
    .p-order-lg-1 {
        order: 1;
    }
    
    .p-order-lg-2 {
        order: 2;
    }
    
    .p-order-lg-3 {
        order: 3;
    }
    
    .p-order-lg-4 {
        order: 4;
    }
    
    .p-order-lg-5 {
        order: 5;
    }
    
    .p-order-lg-6 {
        order: 6;
    }
}

@media screen and (min-width: $xl) {
    .p-order-xl-0 {
        order: 0;
    }
    
    .p-order-xl-1 {
        order: 1;
    }
    
    .p-order-xl-2 {
        order: 2;
    }
    
    .p-order-xl-3 {
        order: 3;
    }
    
    .p-order-xl-4 {
        order: 4;
    }
    
    .p-order-xl-5 {
        order: 5;
    }
    
    .p-order-xl-6 {
        order: 6;
    }
}