.p-ac-start {
    align-content: flex-start;
}

.p-ac-end {
    align-content: flex-end;
}

.p-ac-center {
    align-content: center;
}

.p-ac-around {
    align-content: space-around;
}

.p-ac-stretch {
    align-content: stretch;
}

.p-ac-between {
    align-content: space-between;
}

@media screen and (min-width: $sm) {
    .p-ac-sm-start {
        align-content: flex-start;
    }
    
    .p-ac-sm-end {
        align-content: flex-end;
    }
    
    .p-ac-sm-center {
        align-content: center;
    }
    
    .p-ac-sm-around {
        align-content: space-around;
    }
    
    .p-ac-sm-stretch {
        align-content: stretch;
    }
    
    .p-ac-sm-between {
        align-content: space-between;
    }
}

@media screen and (min-width: $md) {
    .p-ac-md-start {
        align-content: flex-start;
    }
    
    .p-ac-md-end {
        align-content: flex-end;
    }
    
    .p-ac-md-center {
        align-content: center;
    }
    
    .p-ac-md-around {
        align-content: space-around;
    }
    
    .p-ac-md-stretch {
        align-content: stretch;
    }
    
    .p-ac-md-between {
        align-content: space-between;
    }
}

@media screen and (min-width: $lg) {
    .p-ac-lg-start {
        align-content: flex-start;
    }
    
    .p-ac-lg-end {
        align-content: flex-end;
    }
    
    .p-ac-lg-center {
        align-content: center;
    }
    
    .p-ac-lg-around {
        align-content: space-around;
    }
    
    .p-ac-lg-stretch {
        align-content: stretch;
    }
    
    .p-ac-lg-between {
        align-content: space-between;
    }
}

@media screen and (min-width: $xl) {
    .p-ac-xl-start {
        align-content: flex-start;
    }
    
    .p-ac-xl-end {
        align-content: flex-end;
    }
    
    .p-ac-xl-center {
        align-content: center;
    }
    
    .p-ac-xl-around {
        align-content: space-around;
    }
    
    .p-ac-xl-stretch {
        align-content: stretch;
    }
    
    .p-ac-xl-between {
        align-content: space-between;
    }
}